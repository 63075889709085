import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import BackButton from "../components/backButton"
import Seo from "../components/seo"
import Localize from "../components/localize"

import { CareerSvg } from "../svgs/svg"

const Career = ({
  data: { job, info, services, site },
  pageContext,
  location,
}) => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true })
    }
  }, [])
  useEffect(() => {
    if (location.state && location.state.jobs) {
      window.scroll({
        top: location.state.scroll,
        behavior: "smooth",
      })
    }
  }, [location.state])
  const lan = pageContext.locale
  const [scrollPosition, setScrollPosition] = useState(0)
  const [jobs, setJobs] = useState(
    location.state
      ? location.state.jobs
        ? location.state.jobs
        : job.edges
      : job.edges
  )
  const [filter, setFilter] = useState(
    location.state ? (location.state.filter ? location.state.filter : "") : ""
  )
  const career = info
  const allJobs = job.edges
  const filteredJobs = []
  const allServices = services.edges

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const onSearch = event => {
    console.log('.');
    const value = event.target.value
    setFilter(value)
    allJobs.forEach(jo => {
      jo.node.jobCategory.forEach(j => {
        if (j.title === value) {
          filteredJobs.push(jo)
        }
      })
    })
    if (value) {
      setJobs(filteredJobs)
    } else {
      setJobs(allJobs)
    }
  }
  return (
    <>
      <Seo
        lan={lan}
        title={lan === "en" ? "Career" : "Karriere"}
        description={
          lan === "en"
            ? "We want to grow. Grow with us! Apply now and become part of our team. We are looking forward to it!"
            : "Wir wollen wachsen. Wachsen Sie mit uns! Bewerben Sie sich jetzt und werden Sie Teil unseres Teams. Wir freuen uns darauf!"
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        titleTemplate={
          lan === "en"
            ? "Career: HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "Karriere: HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <BackButton
        to={`/${lan}/`}
        state={{ text: "fromCareer" }}
        text={lan === "en" ? "Home" : "Startseite"}
        lan={lan}
      />
      <CareerSvg lan={lan} />
      <div className="simpletext">
        <div className="floater"></div>
        <h1>{career.career_title}</h1>
        {career.career_description.map(car => {
          return car.children.map((ca, index) => {
            return <p key={index}>{ca.text}</p>
          })
        })}
      </div>
      <div className="vacancy-search">
        <div>
          <div className="search dropdown">
            <select
              value={filter}
              id="divisionfilter"
              className="team-dr p-0-35-0-20"
              onBlur={event => onSearch(event)}
              onChange={event => onSearch(event)}
            >
              <option value="">
                {lan && site.siteMetadata && site.siteMetadata[lan].selectField}
              </option>
              {allServices.map((serv, index) => {
                return (
                  <option
                    className="cursor-pointer"
                    key={index}
                    value={serv.node.title}
                  >
                    {serv.node.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="subpagemenu subpagemenuLowPadding">
        <div>
          <div
            id="subpagesubs-0"
            className="d-block swiper-container swiper-container-0 subpagesubs vacancies swiper-container-initialized swiper-container-horizontal swiper-container-android"
          >
            <div className="swiper-wrapper home-page-swiper">
              {jobs.map((job, index) => (
                <Link
                  state={{ scroll: scrollPosition, jobs: jobs, filter: filter }}
                  key={index}
                  to={`/${lan}/career/${job.node.slug.current}/`}
                >
                  <div
                    className="swiper-slide vacancy swiperr"
                    data-divisions="[2]"
                    data-pdf="false"
                  >
                    <p className="divisions">
                      {job.node.jobCategory[0].title.split("&")[0]}{" "}
                      <span className="red">&amp;</span>{" "}
                      {job.node.jobCategory[0].title.split("&").pop()}
                      &nbsp;
                    </p>
                    <h4>{job.node.title}</h4>
                    <p>{job.node.gender}</p>
                    <div className="link">
                      <span style={{ width: "64%" }} className="hs_arrow_right">
                        {lan === "en" ? "To the Details" : "Zu den Details"}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            ></span>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        en {
          selectField
        }
        de {
          selectField
        }
      }
    }
    job: allSanityJob(sort: { fields: title, order: ASC }) {
      edges {
        node {
          jobCategory {
            title {
              _type
              en
              de
            }
          }
          title
          gender
          slug {
            current
          }
        }
      }
    }
    info: sanityStatic {
      career_title {
        _type
        en
        de
      }
      career_description {
        _type
        en {
          children {
            text
          }
        }
        de {
          children {
            text
          }
        }
      }
    }
    services: allSanityMainService(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title {
            _type
            de
            en
          }
        }
      }
    }
  }
`

export default Localize(Career)
